import React, { useMemo } from "react"

import { withMentorRequest } from "./withMentorRequest"
import { routeResolver } from "../Wrappers/Helpers"
import { Hero } from "../Section/Hero/Hero"
import { Wizard } from "../Wizard/Wizard"
import { Container, HeroH1, HeroH4 } from "./MentorRequest.styled"
import { useAvailabilityHelper } from "../../hooks/useAvailabilityHelper"
import { StyledHeroSmall } from "../../styled/Hero"
import { Icon } from "../Icon/Icon"

export const MentorRequest = withMentorRequest(
  ({
    connections,
    meetups,
    mentor: {
      preferences,
      role,
      slug,
      title,
      location,
      membership: { daysAvailable, timesAvailable, sessionType },
    },
    selected,
    loading,
    template: {
      requestAdditional,
      requestAddress,
      requestAddressPlaceholder,
      requestAim,
      requestAimPlaceholder,
      requestConnect,
      requestDate,
      requestDatePlaceholder,
      requestMeetup,
      requestSkills,
    },
    handleSubmit = () => null,
    handleSelect = () => null,
  }: Props): JSX.Element => {
    const { getMentorAvailabilityText } = useAvailabilityHelper()

    const mentorName = title?.split(` `)?.[0] || title
    const hasAvailability = daysAvailable && timesAvailable && sessionType

    const availabilityText = useMemo(() => {
      if (!daysAvailable || !timesAvailable || !sessionType) return null

      return getMentorAvailabilityText({ mentorName, daysAvailable, timesAvailable, sessionType })
    }, [mentorName, daysAvailable, timesAvailable, sessionType])

    const steps = [
      {
        id: `when`,
        title: requestDate,
        reviewTitle: "Preferred Times",
        description: `${availabilityText ? availabilityText : ""}Please share your preferences below.`,
        textInput: [requestDatePlaceholder, "Online or in-person?"],
      },
      {
        id: `where`,
        title: requestAddress,
        reviewTitle: "My location / timezone",
        textInput: requestAddressPlaceholder,
      },
      {
        id: `purpose`,
        title: requestMeetup,
        reviewTitle: "Purpose of Mentor Hour",
        options: meetups?.edges?.map(({ node }) => node) || [],
        textInput: requestAdditional,
      },
      {
        id: `help`,
        title: requestSkills,
        reviewTitle: "Seeking help with",
        options: preferences?.skills || [],
        textInput: requestAdditional,
      },
      {
        id: `outcome`,
        title: requestAim,
        reviewTitle: "Outcome Goals",
        textInput: requestAimPlaceholder,
      },
      // {
      //   id: `method`,
      //   title: requestConnect,
      //   options: connections?.edges?.map(({ node }) => ({ ...node, full: true })) || [],
      //   singleOnly: true,
      // },
    ]

    return (
      <>
        <Hero colour={`secondary`} size={`small`} extended rounded={`sm`}>
          <Container width={`lg`}>
            <HeroH1 large>{title}</HeroH1>
            <HeroH4 small>{role}</HeroH4>
            {location && (
              <StyledHeroSmall>
                <Icon name={"pin"} size={"m"} colour="greyDark" /> {location}
              </StyledHeroSmall>
            )}
          </Container>
        </Hero>
        <Container collapse={`<md`} width={`lg`}>
          <Wizard
            steps={steps?.filter(({ options }) => options?.length !== 0)}
            backLink={routeResolver({ item: { title, slug }, type: `mentor` })}
            doneLink={`/account/requests`}
            onComplete={handleSubmit}
            onSelect={handleSelect}
            selected={selected}
            loading={loading}
            required
            showReview
            reviewTitle={"Please review booking details"}
          />
        </Container>
      </>
    )
  }
)

export interface Props {
  mentor: {
    preferences: any
    role: string
    slug: any
    title: string
    membership: {
      daysAvailable?: string[]
      timesAvailable?: string[]
      sessionType?: string[]
    }
  }
  connections: Array<any>
  meetups: Array<any>
  selected?: any
  template?: any
  loading?: boolean
  handleSubmit?: () => void
  handleSelect?: () => void
}
