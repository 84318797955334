import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { withMembership } from "../../hoc/withMember"
import { MentorRequest as Template } from "../../components/Mentor/MentorRequest"

export const query = graphql`
  query($id: String!) {
    mentor: sanityMentor(_id: { eq: $id }) {
      ...GatsbyMentorFragment
    }
    meetups: allSanityMentorMeetup {
      edges {
        node {
          title
          id
        }
      }
    }
    connections: allSanityMentorConnect {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`

export default withAuthentication(withMembership(({ data, ...props }): JSX.Element => <Template {...props} {...data} />))
