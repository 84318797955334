export const useAvailabilityHelper = () => {
  const getDaysText = (days: string[], and: string = "&") =>
    days.some(d => ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays", "Sundays"].includes(d))
      ? days.join(", ").replace(/,([^,]*)$/, ` ${and}$1`)
      : days[0]

  const getTimesText = (times: string[], and: string = "&") => times.join(", ").replace(/,([^,]*)$/, ` ${and}$1`)

  const getSessionText = (sessionTypes: string[], and: string = "&") => sessionTypes.join(", ").replace(/,([^,]*)$/, ` ${and}$1`)

  const getMentorAvailabilityText = ({ mentorName, daysAvailable, timesAvailable, sessionType }) => {
    if (!daysAvailable?.length || !timesAvailable?.length || !sessionType?.length) return null

    const daysText = getDaysText(daysAvailable)
    const timesText = getTimesText(timesAvailable)
    const sessionTypeText = getSessionText(sessionType)

    return `${mentorName} is generally available most ${daysText.replace("all ", "")}, ${
      timesText === "whole day" ? "at any time" : `in the ${timesText} (local time)`
    }, and prefers ${sessionTypeText} sessions. `
  }

  return { getDaysText, getTimesText, getSessionText, getMentorAvailabilityText }
}
